body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.fade-enter {
	opacity: 0;
}
.fade-exit {
	opacity: 1;
}
.fade-enter-active {
	opacity: 1;
}
.fade-exit-active {
	opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
	transition: opacity 500ms;
}
#Keyops__hamburgerButton {
  width: 30px;
  height: 22px;
  /* position: relative; */
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#Keyops__hamburgerButton {
}

#Keyops__hamburgerButton span {
  display: block;
  position: absolute;
  height: 4px;
  width: 50%;
  background: #0e75d7;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#Keyops__hamburgerButton span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#Keyops__hamburgerButton span:nth-child(odd) {
  left:0px;
  border-radius: 9px 0 0 9px;
}

#Keyops__hamburgerButton span:nth-child(1), #Keyops__hamburgerButton span:nth-child(2) {
  top: 0px;
}

#Keyops__hamburgerButton span:nth-child(3), #Keyops__hamburgerButton span:nth-child(4) {
  top: 9px;
}

#Keyops__hamburgerButton span:nth-child(5), #Keyops__hamburgerButton span:nth-child(6) {
  top: 18px;
}

#Keyops__hamburgerButton.open span:nth-child(1),#Keyops__hamburgerButton.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#Keyops__hamburgerButton.open span:nth-child(2),#Keyops__hamburgerButton.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#Keyops__hamburgerButton.open span:nth-child(1) {
  left: 3px;
  top: 3px;
}

#Keyops__hamburgerButton.open span:nth-child(2) {
  left: calc(50% - 2px);
  top: 3px;
}

#Keyops__hamburgerButton.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#Keyops__hamburgerButton.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#Keyops__hamburgerButton.open span:nth-child(5) {
  left: 1px;
  top: 15.5px;
}

#Keyops__hamburgerButton.open span:nth-child(6) {
  left: calc(50% - 1px);
  top: 15.5px;
}
